'use strict';

angular.module('mvpcarrefourApp')
    .controller('CategoryCtrl', ['$scope', '$rootScope', '$stateParams', 'Product', 'Category', '_categories', 'Modal', '$location', '$state',
        function($scope, $rootScope, $stateParams, Product, Category, _categories, Modal, $location, $state) {

            // Calculamos la categoria que se va a seleccionar de manera aleatoria (Salvo que la recibamos especificamente por la URL)
            const categoryIndex = _.findIndex(_categories, c => {
                return c.slug === $stateParams.slugCategory
            })

            // Guardamos en el scope la categoria que ha sido seleccionada
            const _selectedCategory = _categories[categoryIndex];           

            //Finalmente insertamos en el scope todas los objetos que se usaran en la vista
            $scope.categories = _categories;
            $scope.selectedCategory = _selectedCategory;
           
            Product.category({ id: _selectedCategory.slug, limit: 0 })
                .$promise.then(function(_products) {                   
                    $scope.items = _.shuffle(_products);                    
                });

            //Determina la categoria activa si se selecciona
            $scope.selectedIndex = categoryIndex;
            $scope.select = function(i) {
                $scope.selectedIndex = i;
            };

            // Creo el filtro vacio que se llenara cuando se active el estado subcategoria -> crl subcategoria
            $scope.search = [];

            //Filtro con ngclick. Así filtrariamos solo con el evento click sobre cualquier subcategoria
            $scope.filterProductsBySubCategory = function(subcategory) {

                $scope.search = subcategory;
                $scope.option = _.startCase(_.replace(subcategory,/-/g,' '));
                $scope.currentActive = subcategory; 
               
                // Si hay una ficha abierta y se filtra a otra subcategoria no se produce un cambio de estado, y se mantiene en un estado incorrecto main.category.product
                // por lo tanto reconducimos a su estado main.category propio   
               
                $state.go('main.vote.category', { slugCategory: $scope.selectedCategory.slug }, {
                    location: true,
                    notify: false
                });                             

            };

             $scope.filterFunction = function(element) {                  
                return ($scope.search.length != 0) ? element.categories[0].slug == $scope.search : true ;
            };

           
            // Mobile
            $scope.modalShowCategories = Modal.full.all(_categories, categoryIndex);
        }
    ]);
